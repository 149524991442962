@font-face {
    font-family: "Poppins";
    src: url("../../assets/font/Poppins.ttf") format("truetype");
}
@font-face {
    font-family: "Montserrat";
    src: url("../../assets/font/Montserrat-VariableFont_wght.ttf") format("truetype");
}

body {
    font-family: 'Poppins', serif;
    overflow-y: hidden;
}
